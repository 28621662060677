<template>
  <v-row justify="start">
    <v-dialog v-model="dialogAlert" persistent max-width="370px">
      <v-card class="radius-card hide_overflow" flat>
        <div class="d-flex justify-end pa-3">
          <v-btn icon @click="$emit('close')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <div class="" style="min-height: 250px">
          <div class="d-flex justify-center mb-5">
            <img src="@/assets/img/alert-warn.png" height="100px" alt="" />
          </div>
          <h3 class="text-center">Sesi konseling tersisa 5 menit lagi!</h3>
        </div>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "alertQuiz",
  props: ["dialogAlert", "loading"],

  data() {
    return {
      role: ""
    };
  },
  methods: {
    cancelStore() {
      this.$emit("close");
      this.$emit("refetch");
    }
  }
};
</script>
