<!-- eslint-disable prettier/prettier -->
<template>
  <div class="_bg-default d-flex pa-3" style="height: 100vh">
    <div style="width: 20%" v-if="dataUser">
      <v-card class="mr-5 mb-5 pa-3 radius-card" flat height="max-content">
        <div class="info_receiver d-flex align-center" v-if="role == 4">
          <img
            :src="
              `${env}/upload/photo_profile/${dataUser.id}/${dataUser.photo_profile}`
            "
            class="dp mr-3"
            alt=""
          />
          <b> {{ dataUser.nama_lengkap }} </b>
        </div>
        <div v-if="role == 3">
          <div class="info_receiver d-flex align-center mb-3">
            <img
              :src="dataUser.photo ? dataUser.photo : dummy"
              class="dp mr-3"
              alt=""
            />
            <b>
              {{ dataUser.nama_lengkap }}
            </b>
          </div>
          <v-btn
            v-if="fsInfo"
            block
            @click="getMasalah()"
            color="#3B053C"
            dark
            depressed
            >Lihat Permasalahan</v-btn
          >
        </div>
      </v-card>

      <v-card
        class="mr-5 mt-3 mb-5 pa-3 radius-card d-flex justify-center align-center"
        flat
        height="max-content"
        v-if="timeLeft < duration"
      >
        <v-icon class="mr-2" color="black">mdi-alarm</v-icon>
        <p class="font-weight-bold mb-0">
          {{ formatedDrt }}
        </p>
      </v-card>
      <!-- ALERT -->
      <div v-if="role != 3">
        <v-card
          class="mr-5 mb-5 pa-3 radius-card warningTimeOut"
          flat
          height="max-content"
          color="#FFEBEE"
          v-if="timeLeft >= duration"
        >
          <v-icon color="red">mdi-alert</v-icon>
          <p class="ma-0 red--text">Waktu konseling sudah habis!</p>
        </v-card>
        <v-card
          class="mr-5 mb-5 pa-3 radius-card"
          flat
          height="max-content"
          color="#FFF3E0"
          v-else-if="timeLeft >= warningTime"
        >
          <v-icon color="orange">mdi-alert</v-icon> {{ alertMinute + 1 }} menit
          lagi
        </v-card>
      </div>
    </div>
    <v-card flat class="radius-card card_chat" min-height="95vh" width="65%">
      <div class="head d-flex flex-column justify-space-between">
        <div class="d-flex justify-end align-center pa-2" v-if="isEnd">
          <v-btn
            v-if="isEnd.is_done !== 1 && role == 3"
            color="red"
            @click="endChat"
            depressed
            dark
            >End Chat</v-btn
          >
        </div>
        <v-progress-linear
          :value="percentTimer"
          color="orange"
          height="10px"
        ></v-progress-linear>
      </div>
      <!-- OVERLAY STUFF -->
      <div>
        <v-overlay :absolute="true" color="white" opacity="1" :value="loading">
          <!-- //loading component -->
          <v-progress-circular
            indeterminate
            class="mr-2"
            color="purple"
          ></v-progress-circular>
          <b class="purple--text">Loading...</b>
        </v-overlay>
        <!-- persiapan upload foto -->
        <v-overlay
          :absolute="true"
          color="#3b053c"
          opacity="1"
          :value="preUpload"
        >
          <div class="d-flex justify-end">
            <v-btn icon dark @click="cancelSendImg">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
          <div class="d-flex align-center justify-center">
            <div class="img_place">
              <div class="d-flex justify-center mb-2">
                <img
                  :src="tempPic"
                  height="300px"
                  style="border-radius: 8px"
                  alt=""
                />
              </div>
              <div class="d-flex">
                <v-textarea
                  class="typing pa-0"
                  v-model="msg"
                  rows="1"
                  id="chat-message-typer-textarea"
                  auto-grow
                  row-height="10"
                  :disabled="sending"
                  placeholder="Ketik disini..."
                  rounded
                  solo
                  dark
                  flat
                  dense
                  ref="typist"
                  hide-details
                ></v-textarea>
                <v-btn
                  width="45px"
                  height="45px"
                  depressed
                  class="ml-2"
                  color="blue"
                  v-if="!sending"
                  dark
                  fab
                  @click="checkType('image')"
                >
                  <v-icon>mdi-send</v-icon>
                </v-btn>
                <v-btn
                  width="45px"
                  height="45px"
                  depressed
                  class="ml-2"
                  color="blue"
                  dark
                  fab
                  v-if="sending"
                >
                  <v-progress-circular
                    v-if="sending"
                    indeterminate
                    size="25"
                  ></v-progress-circular>
                </v-btn>
              </div>
            </div>
          </div>
        </v-overlay>
      </div>
      <!-- COMPONENT CHATING -->
      <div class="temp_chat pa-3">
        <div v-for="(chat, n) in allMsg" :key="`chatke-${n}`">
          <div
            :id="chat.id"
            :class="chat.from_id == id ? 'my_temp' : 'your_temp'"
          >
            <div :class="chat.from_id == id ? 'my_half' : 'your_half'">
              <v-hover v-slot="{ hover }">
                <div :class="chat.from_id == id ? 'my_ballon' : 'your_ballon'">
                  <div>
                    <div
                      class="
                        ur_reply
                        black--text
                        pre_format
                        d-flex
                        align-center
                      "
                      v-if="chat.reply"
                      @click="scrollTo(chat)"
                    >
                      <p class="ma-0 mr-2" v-snip="4">
                        {{ chat.reply.text }}
                      </p>
                      <div v-if="chat.reply.image">
                        <img
                          :src="chat.reply.image"
                          class="reply_img"
                          alt="rpl"
                        />
                      </div>
                    </div>
                    <div v-if="chat.image">
                      <img
                        :src="chat.image"
                        @click="viewImage(chat)"
                        class="image_in_msj"
                        alt=""
                      />
                    </div>
                    <p class="ma-0 pre_format" v-html="chat.text"></p>
                    <div class="d-flex align-center mt-1">
                      <small class="mr-1"
                        >{{ $date(chat.createdAt).format("HH:mm") }} |
                        {{ $date(chat.createdAt).format("DD/MM") }}</small
                      >
                      <div v-if="chat.from_id == id">
                        <v-icon
                          small
                          color="blue"
                          content="Pesan telah diterima"
                          v-tippy="{
                            arrow: true,
                            arrowType: 'round',
                            animation: 'fade',
                            theme: 'light',
                          }"
                          v-if="chat.read"
                          >mdi-check-all</v-icon
                        >
                        <v-icon
                          small
                          content="Pesan telah terkirim"
                          v-tippy="{
                            arrow: true,
                            arrowType: 'round',
                            animation: 'fade',
                            theme: 'light',
                          }"
                          v-if="!chat.read"
                          >mdi-check</v-icon
                        >
                      </div>
                    </div>
                  </div>
                  <v-expand-transition>
                    <div
                      class="
                        rpl_btn
                        d-flex
                        justify-end
                        transition-fast-in-fast-out
                      "
                      v-if="hover"
                    >
                      <div
                        class="temp_rpl d-flex align-center"
                        @click="configReply(chat)"
                      >
                        <v-icon dark class="mr-1" small>mdi-reply</v-icon>
                        <p class="ma-0 white--text small_txt">Reply</p>
                      </div>
                    </div>
                  </v-expand-transition>
                </div>
              </v-hover>
            </div>
          </div>
        </div>
      </div>
      <!-- component input text -->
      <div class="temp_type">
        <div class="container_rpl d-flex pa-3" v-if="isReply">
          <div class="point_rpl">
            <v-icon color="blue"> mdi-reply </v-icon>
          </div>
          <div class="rpl_txt d-flex justify-space-between" v-if="dataReply">
            <div class="d-flex align-center">
              <p class="ma-0 mr-2" v-snip="2">
                {{ dataReply.text }}
              </p>
            </div>
            <div class="ml-2" v-if="dataReply.image !== null">
              <img :src="dataReply.image" class="prereply_img" alt="" />
            </div>
          </div>
          <div class="point_rpl d-flex justify-end">
            <v-btn
              depressed
              fab
              color="white"
              @click="configReply(false)"
              x-small
              ><v-icon>mdi-close</v-icon></v-btn
            >
          </div>
        </div>
        <v-progress-circular
          v-if="sending"
          class="progress_abs"
          indeterminate
          size="25"
          color="grey"
        ></v-progress-circular>
        <div class="text_area d-flex align-center pa-3">
          <div class="upload-btn">
            <v-btn
              width="45px"
              height="45px"
              depressed
              class="mr-2"
              color="white"
              fab
            >
              <v-icon color="blue">mdi-paperclip</v-icon>
            </v-btn>
            <input
              @change="showImg($event)"
              type="file"
              class="widh_inpt"
              accept="image/x-png,image/gif,image/jpeg"
              name="myfile"
            />
          </div>
          <v-textarea
            class="typing pa-0"
            v-model="msg"
            rows="1"
            id="chat-message-typer-textarea"
            auto-grow
            row-height="10"
            :disabled="sending"
            placeholder="Ketik disini..."
            rounded
            solo
            dense
            ref="typist"
            hide-details
            @keydown.enter.exact.prevent="checkType('text')"
            @keydown.enter.shift.exact.prevent="msg += '\n'"
          ></v-textarea>
        </div>
      </div>
    </v-card>
    <v-overlay
      :absolute="true"
      color="white"
      opacity="1"
      :value="haventStarted"
    >
      <h1 class="black--text">Belum masuk waktu konsultasi</h1>
      <v-btn class="blue" @click="$router.go(-1)">Back</v-btn>
    </v-overlay>
    <!-- <div class="overflows">
      <div class="temp_jam pa-3 d-flex flex-column alig-center">
        <v-icon>mdi-alarm</v-icon>
        <p class="text-center ma-0 mt-1">{{ timeLeft }}</p>
      </div>
    </div> -->
    <div>
      <v-snackbar
        v-model="snackbar"
        :color="alertMsg.color"
        :timeout="-1"
        :multi-line="true"
      >
        <b>{{ alertMsg.msg }}</b>

        <template v-slot:action="{ attrs }">
          <v-btn dark text v-bind="attrs" @click="confirmAlert(true)">
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </div>
    <div class="pa-2">
      <alert-chat :dialogAlert="dialogAlert" @close="dialogAlert = false" />
      <view-img
        :dialogViewImg="dialogViewImg"
        :dataImg="dataViewImg"
        @close="dialogViewImg = false"
      />
      <view-masalah
        :d_masalah="d_masalah"
        :masalah="masalah"
        @close="d_masalah = false"
      />
    </div>
  </div>
</template>

<script>
import firebase from "firebase";
import { mapState } from "vuex";
import alertChat from "./alertChat.vue";
import ViewImg from "./viewImg.vue";
import Swal from "sweetalert2";
import ViewMasalah from "../../../components/Counseling/Psycholog/Modal/viewMasalah.vue";
import Compressor from "compressorjs";
export default {
  components: { alertChat, ViewImg, ViewMasalah },
  name: "chatroom",
  computed: {
    ...mapState({
      id: (state) => state.id,
      role: (state) => state.role,
      env: (state) => state.API_URL,
    }),
  },
  data() {
    return {
      loading: false, //ini loading, awas aja kalo nanya lagi
      sessionInfo: null, //info sesi
      thread: null, //id thread
      interval: null, //interval check time
      timeLeft: null, // remaining time
      duration: null, //durasi konseling
      msg: "", //value kotak typing...
      typeMsg: "text", //tipe mesej
      allMsg: [], //list all mesej
      sending: false, //when mesej is sending
      dataReply: null, //data pesan yang di pilih ketika reply
      isReply: false, //status apakah dia lagi ngereply
      tempPic: null, //template picture
      preUpload: false, //pra upload
      dataUser: null, //data user
      dialogAlert: false,
      second: null,
      minute: null,
      hour: null,
      dialogViewImg: false,
      dataViewImg: null,
      isEnd: null,
      snackbar: false,
      isAlertConfirmed: false,
      alertMinute: null,
      warningTime: null,
      percentTimer: null,
      haventStarted: false,
      formatedDrt: null,
      alertMsg: {
        color: "",
        msg: "",
      },
      isStart: 0,
      fsInfo: null,
      d_masalah: false,
      masalah: "",
      profile: null,
    };
  },

  mounted() {
    this.fetchData();
    this.onFocused();
    this.getProfile();
  },
  methods: {
    getProfile() {
      this.loading = true;
      // public/my-profile
      let data = {
        path: `${this.role == "3" ? "user" : "public"}/my-profile`,
      };
      this.$store
        .dispatch("getApi", data)
        .then((data) => {
          let temporarry = data[0];
          this.profile = {
            _id: temporarry.id,
            name: temporarry.nama_lengkap,
          };
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    getMasalah() {
      this.masalah = this.fsInfo.permasalahan;
      this.d_masalah = true;
    },
    confirmAlert(state) {
      this.isAlertConfirmed = state;
      this.snackbar = false;
    },
    viewImage(item) {
      this.dataViewImg = item;
      this.dialogViewImg = true;
    },
    showImg(e) {
      const file = e.target.files[0];
      let vm = this;
      new Compressor(file, {
        quality: 1,
        maxWidth: 700,
        success(res) {
          var reader = new FileReader();
          reader.readAsDataURL(res);
          reader.onloadend = () => {
            vm.tempPic = reader.result;
            vm.preUpload = true;
          };
        },
        error(err) {
          console.log(err.message);
        },
      });
    },
    cancelSendImg() {
      this.tempPic = null;
      this.preUpload = false;
    },
    scrollTo(chat) {
      let id = chat.reply.id;
      let tempChat = document.getElementById(id);
      tempChat.scrollIntoView();
      tempChat.style.background = "#9C27B0";
      setTimeout(() => {
        tempChat.style.background = "none";
      }, 1000);
    },
    // focus ke text area
    onFocused() {
      setTimeout(() => {
        if (this.$refs["typist"]) {
          this.$refs["typist"].$refs.input.focus();
        }
      }, 100);
    },
    configReply(item) {
      if (item) {
        this.dataReply = item;
        this.isReply = true;
      } else {
        this.dataReply = null;
        this.isReply = false;
      }
      this.onFocused();
    },
    // check waktu tersisa
    remainingTime(start_time) {
      this.warningTime = this.duration - 300;

      this.interval = setInterval(() => {
        let now = this.$date();
        let left = this.$date(now).diff(start_time, "second");
        // let left = this.$date(now).diff("2021-12-07 17:16:00", "second");
        this.percentTimer = ((this.duration - left) / this.duration) * 100;
        let leftSec = this.duration - left;
        this.formatedDrt = this.$date
          .duration(leftSec, "second")
          .format("HH:mm:ss");
        if (left >= this.duration) {
          clearInterval(this.interval);
        }
        this.timeLeft = left;

        this.alertMinute = Math.floor((this.duration - left) / 60);
        if (this.timeLeft >= this.duration) {
          if (!this.snackbar) {
            this.alertMsg = {
              color: "red",
              msg: "Sesi konseling sudah habis!",
            };
            this.snackbar = true;
          }
        } else if (this.timeLeft >= this.warningTime) {
          if (!this.snackbar && !this.isAlertConfirmed) {
            this.alertMsg = {
              color: "orange",
              msg: `waktu konseling tersisa ${this.alertMinute} menit lagi`,
            };
            this.snackbar = true;
          }
        }
      }, 1000);
    },
    // ambil data konseling
    fetchData() {
      let endpoint = null;
      if (this.role == 3) {
        endpoint = "trxPsyByd";
      } else {
        endpoint = "viewTransaction";
      }
      this.loading = true;
      let data = this.$route.params.id;
      this.$store.dispatch(`counseling/${endpoint}`, data).then((data) => {
        let item = data[0];
        if (this.role == 4) {
          this.dataUser = item.psycholog;
        } else if (this.role == 3) {
          this.dataUser = item.public;
        }
        let session = item.sessions.find((el) => {
          return el.session == item.session_on;
        });
        let now = this.$date();
        let left = this.$date(now).diff(
          session.schedule.booking_time,
          "second",
        );
        if (left >= 0) {
          this.haventStarted = false;
        } else {
          this.haventStarted = true;
        }
        this.sessionInfo = session;
        this.thread = session.konsultasi_schedule_id.toString();
        let db = firebase.firestore();

        db.collection("konsultasi")
          .doc(this.thread)
          .onSnapshot((doc) => {
            this.fsInfo = doc.data();
            if (this.fsInfo.is_start == 1) {
              this.duration = session.schedule.duration * 60;
              this.remainingTime(this.fsInfo.start_time);
            }
            this.fetchMsg();
            this.loading = false;
          });
      });
    },
    // ngelisten pesan pesan
    fetchMsg() {
      let db = firebase.firestore();
      let collection = db
        .collection("messages")
        .doc(this.thread)
        .collection(this.thread);

      collection.orderBy("createdAt").onSnapshot((querySnapshot) => {
        let data = [];
        querySnapshot.forEach((doc) => {
          let msg = doc.data();
          if (msg.from_id != this.id && !msg.read) {
            this.changeStatusRead(msg);
          }
          data.push(msg);
        });
        this.allMsg = data;
        this.checkEndchat();
      });
    },

    changeStatusRead(item) {
      let db = firebase.firestore();
      let collection = db
        .collection("messages")
        .doc(this.thread)
        .collection(this.thread)
        .doc(item.id);

      collection.update({
        read: true,
      });
    },
    // ngelisten pesan pesan
    checkEndchat() {
      let db = firebase.firestore();
      let collection = db.collection("konsultasi").doc(this.thread);
      collection.onSnapshot((querySnapshot) => {
        this.isEnd = querySnapshot.data();
        if (!this.sessionInfo.schedule.rating) {
          if (this.role == 4) {
            if (this.isEnd.is_done == 1) {
              let id = this.$route.params.id;
              this.$router.push(`/done-counseling/${id}`);
            }
          }
        }
      });
    },
    checkType(type) {
      this.typeMsg = type;
      if (type == "text") {
        if (this.msg !== "") {
          this.sendMsg();
        }
      } else {
        this.sendMsg();
      }
    },
    // kirim pesan
    sendMsg() {
      this.sending = true;
      let reply = null;
      if (this.isReply) {
        reply = {
          id: this.dataReply.id,
          from_id: this.dataReply.from_id,
          text: this.dataReply.text,
          type: this.dataReply.type,
          image: this.dataReply.image,
          user: this.dataReply.user,
          vn: this.dataReply.vn,
        };
      }
      let db = firebase.firestore();
      let collection = db
        .collection("messages")
        .doc(this.thread)
        .collection(this.thread);
      let doc = db.collection("messages").doc(this.thread);

      collection.get().then((qty) => {
        let countMsg = qty.size + 1;
        let docId = `m${countMsg}`;
        doc
          .set({
            last_update: new Date(),
          })
          .then(() => {
            collection
              .doc(docId)
              .set({
                id: docId,
                from_id: this.id,
                text: this.msg,
                image: this.tempPic,
                vn: "",
                type: this.typeMsg,
                user: this.profile,
                read: false,
                createdAt: this.$date
                  .tz(new Date(), "Asia/Jakarta")
                  .format("YYYY-MM-DD HH:mm:ss"),
                reply: reply,
              })
              .then(() => {
                this.msg = "";
                this.sending = false;
                this.isReply = false;
                this.onFocused();
                this.cancelSendImg();
              });
          });
      });
    },
    endChat() {
      let data = {
        schedule_id: this.sessionInfo.konsultasi_schedule_id,
      };

      this.$store.dispatch("counseling/endchat", data).then(() => {
        this.$router.push("/psikolog/counseling");
        Swal.fire({
          icon: "success",
          title: "Sesi konseling telah berakir",
          showConfirmButton: false,
          timer: 1500,
        });
      });
    },
  },
};
</script>

<style>
.pre_format {
  word-break: break-all;
  white-space: pre-wrap;
}
.card_chat {
  position: relative;
}
.head {
  height: 10vh;
  background: #3b053c;
}
.temp_chat {
  height: 75vh;
  background: #f9f2fa;
  overflow-y: scroll;
}
.temp_type {
  position: absolute;
  bottom: 0;
  width: 100%;
  min-height: 10vh;
  background: #3b053c;
}
.typing {
  font-size: small;
  line-height: 1.6 !important;
}
.v-textarea textarea {
  line-height: 1.5 !important;
  margin-top: 15px !important;
}
.my_temp {
  padding: 5px;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  transition: 0.5s;
  /* margin-bottom: 10px; */
  border-radius: 5px;
}
.my_half {
  display: flex;
  justify-content: flex-end;
  width: 70%;
}
.my_ballon {
  position: relative;
  /* display: flex; */
  background: white;
  padding: 5px;
  border-radius: 10px 10px 0 10px;
  box-shadow: 2px 3px 44px -32px rgba(0, 0, 0, 0.75);
  transition: 2s all !important;
}
.ur_reply {
  padding: 5px;
  border-radius: 8px;
  background: rgb(222, 222, 222);
  cursor: pointer;
}
.your_temp {
  padding: 5px;
  display: flex;
  justify-content: flex-start;
  width: 100%;
  transition: 0.5s;
  /* margin-bottom: 10px; */
  border-radius: 5px;
}
.your_half {
  display: flex;
  justify-content: flex-start;
  width: 70%;
}

.your_ballon {
  position: relative;
  /* display: flex; */
  background: #3b053c;
  padding: 5px;
  color: #fff;
  border-radius: 0 10px 10px 10px;
  box-shadow: 2px 3px 44px -32px rgba(0, 0, 0, 0.75);
  transition: 2s all !important;
}
.overflows {
  position: absolute;
  top: 10px;
  left: 10px;
}
.overflow_alert {
  position: absolute;
  top: 10px;
}
.temp_jam {
  width: 70px;
  height: 70px;
  border-radius: 8px;
  background: white;
  box-shadow: 3px 13px 26px -11px rgba(59, 5, 60, 1);
}
.progress_abs {
  position: absolute;
  right: 20px;
  top: 22px;
  z-index: 2 !important;
}

.temp_rpl {
  padding: 3px;
  cursor: pointer;
  border-radius: 5px;
  background: grey;
}
.container_rpl {
  width: 100%;
  background: #fff;
}
.point_rpl {
  width: 5%;
}
.rpl_txt {
  width: 90%;
}
.text_area {
  min-height: 10vh;
}
.upload-btn {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.upload-btn input[type="file"] {
  cursor: pointer;
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  width: 45px;
  height: 45px;
}
.img_place {
  padding: 10px;
  background: #fff;
  border-radius: 8px;
}
#chat-message-typer-textarea {
  max-height: 115px;
  overflow-y: auto;
}
.image_in_msj {
  width: 250px;
  height: 250px;
  border-radius: 8px;
  object-fit: cover;
  cursor: pointer;
}
.prereply_img {
  width: 70px;
  height: 70px;
  border-radius: 8px;
  object-fit: cover;
  cursor: pointer;
}
.reply_img {
  width: 50px;
  height: 50px;
  border-radius: 5px;
  object-fit: cover;
}
.dp {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  cursor: pointer;
}

.warningTimeOut {
  /* Start the shake animation and make the animation last for 0.5 seconds */
  animation: shake 0.5s;

  /* When the animation is finished, start again */
  animation-iteration-count: infinite;
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}
</style>
